<template>
  <div id="contact">
    <div class="container contact-main-page">
      <div class="row">
        <div class="col-lg-12">
          <div class="contact-page-side-content">
            <!-- <h3 class="contact-page-title">Contact Us</h3> -->
            <!-- <p class="contact-page-message"></p> -->
            <div class="single-contact-block">
              <h4><i class="fa fa-fax"></i> {{ $i18n.Contact.Address }}</h4>
              <p>{{ datas.address }}</p>
            </div>
            <div class="single-contact-block">
              <h4><i class="fa fa-phone"></i> {{ $i18n.Contact.Phone }}</h4>
              <p>{{ datas.tel }}</p>
            </div>
            <div class="single-contact-block last-child">
              <h4>
                <i class="fa fa-envelope-o"></i> {{ $i18n.Contact.Email }}
              </h4>
              <p>{{ datas.email }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6" v-if="false">
          <div class="contact-form-content">
            <h3 class="contact-page-title">Tell Us Your Message</h3>
            <div class="contact-form">
              <form
                id="contact-form"
                action="http://hasthemes.com/file/mail.php"
                method="post"
              >
                <div class="form-group">
                  <label>Your Name <span class="required">*</span></label>
                  <input
                    type="text"
                    name="con_name"
                    id="con_name"
                    required=""
                  />
                </div>
                <div class="form-group">
                  <label>Your Email <span class="required">*</span></label>
                  <input
                    type="email"
                    name="con_email"
                    id="con_email"
                    required=""
                  />
                </div>
                <div class="form-group">
                  <label>Subject</label>
                  <input type="text" name="con_subject" id="con_subject" />
                </div>
                <div class="form-group form-group-2">
                  <label>Your Message</label>
                  <textarea name="con_message" id="con_message"></textarea>
                </div>
                <div class="form-group">
                  <button
                    type="submit"
                    value="submit"
                    id="submit"
                    class="torress-contact-form_btn"
                    name="submit"
                  >
                    send
                  </button>
                </div>
              </form>
            </div>
            <p class="form-messege"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    datas() {
      return this.$store.state.shopInfo
    }
  }
}
</script>
<style lang="less">
@border-color: #ebebeb;
@body-text_color: #333333;
@primary-color: #fed100;
@bg-white_color: #fff;

@white-smoke_color: #f4f4f4;

.contact-main-page {
  // padding: 85px 0 0;

  .contact-page-side-content {
    background-color: @white-smoke_color;
    padding: 25px 30px 0;
    margin-top: 90px;

    .contact-page-message {
      margin-bottom: 0;
      padding-bottom: 25px;
    }

    .single-contact-block {
      padding-bottom: 3px;
      margin-bottom: 27px;
      border-bottom: 1px solid @border-color;

      &:last-child {
        border-bottom: none;
      }

      h4 {
        margin-bottom: 0;
        padding-bottom: 25px;
      }

      p {
        margin-bottom: 0;
        padding-bottom: 20px;
      }

      &.last-child {
        > h4 {
          padding-bottom: 20px;
        }
      }
    }
  }

  .contact-page-title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  /* ---Contact Form Area--- */
  .contact-form-content {
    margin-top: 90px;

    .contact-form {
      .form-group {
        margin-bottom: 20px;

        &.form-group-2 {
          margin-bottom: 25px;
        }

        label {
          display: block;
          font-size: 15px;
          line-height: 29px;
          font-weight: 400;
          margin-bottom: 20px;
          cursor: text;

          span,
          .required {
            color: #e01212;
          }
        }

        input {
          background-color: @bg-white_color;
          width: 100%;
          height: 50px;
          line-height: 50px;
          outline: none;
          border: 1px solid @border-color;
          padding-left: 20px;
        }

        textarea {
          background-color: @bg-white_color;
          width: 100%;
          outline: none;
          border: 1px solid @border-color;
          padding-left: 20px;
          line-height: 50px;
          height: 200px;
          color: inherit;
        }

        .torress-contact-form_btn {
          line-height: 12px;
          display: inline-block;
          padding: 16px 40px;
          font-weight: 600;
          border: 2px solid @primary-color;
          border-radius: 25px;
          text-transform: capitalize;
          transition: all 0.3s ease-in;

          &:hover {
            background-color: @primary-color;
            border-color: @primary-color;
            color: @bg-white_color;
          }
        }
      }

      [class*='form-group'] {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  /* ---Form Message Area--- */
  p {
    &.form-messege {
      margin-bottom: 0;
    }
  }
}
</style>
